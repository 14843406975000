import "./formCard.scss"
import React from "react"
import { Button } from "../../Layout/Buttons/buttons"
import RichText from "../../Modules/RichText/richText"

const FormCard = ({ data }) => {

  return (
    <div className={`formCard__wrapper formCard__${data.type}`}>
      <div className={`formCard__header`}>
        <img alt={data.title} src={data.formImage.fluid.src}/>
        <div className={`formCard__header-main`}>
          <h2 className={`formCard__header-title`}>{data.formTitle} {data.name}</h2>
          <Button withArrow primary label={`Complete ${data.name} Online`} link={data.slug}/>
        </div>
      </div>
      <RichText content={data.requeriments.json}/>
      <div className={`formCard__download`}>
        <a href={data.governmentPdf.file.url} target={'_blank'}><span/> Download {data.name} Form</a>
      </div>
    </div>
  )

}
export default FormCard