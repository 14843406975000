import "./steps.scss"
import React from "react"
import Wrapper from "../../Layout/Wrapper/wrapper"


const Steps = ({ data }) => {
  return (
    <Wrapper isFlex classNames={`steps`}>
      <ul className={`steps__list`}>
        {data.edges.map((item, index) => {
          let response = (data.edges.length === index + 1) ? `${item.node.step} <br/><a href="https://travel.state.gov/content/passports/en/passports.html" target="_blank">Visit Government Site</a>` : item.node.step
          return (
            <li key={index} className={`steps__item medium`}>
              <p dangerouslySetInnerHTML={{ __html: response }}/>
            </li>
          )
        })}
      </ul>
    </Wrapper>
  )

}
export default Steps