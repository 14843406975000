import "./OurBenefits.scss"
import React from "react"
import Wrapper from "../../Layout/Wrapper/wrapper"
import oneDay from "../../../images/svg/24hours.svg"
import simpleForm from "../../../images/svg/simpleform.svg"
import errorChecking from "../../../images/svg/errorchecking.svg"
import easyStep from "../../../images/svg/stepbystep.svg"

const OurBenefits = ({ data }) => {
  return (
    <Wrapper isFlex classNames={`our-benefits`}>
      <div className={`our-benefits__present`}>
        <h3 className={`our-benefits__present__title large`}>Our Benefits</h3>
        <p className={`our-benefits__present__desc medium`}>Receive a neatly prepared passport form and personalized
          filling instructions by using our online form and
          filling software</p>
      </div>
      <ul className={`our-benefits__list`}>
        <li className={`our-benefits__item`}>
          <img alt={data.benefit1} src={simpleForm}/>
          <p className={`medium`}>{data.benefit1}</p>
        </li>
        <li className={`our-benefits__item`}>
          <img alt={data.benefit2} src={errorChecking}/>
          <p className={`medium`}>{data.benefit2}</p>
        </li>
        <li className={`our-benefits__item`}>
          <img alt={data.benefit3} src={easyStep}/>
          <p className={`medium`}>{data.benefit3}</p>
        </li>
        <li className={`our-benefits__item`}>
          <img alt={data.benefit4} src={oneDay}/>
          <p className={`medium`}>{data.benefit4}</p>
        </li>
      </ul>
    </Wrapper>
  )

}
export default OurBenefits