import React, { Component } from "react"
import Layout from "../components/Layout/layout"
import { JumbotronSimple } from "../components/Layout/Jumbotron/jumbotron"
import Wrapper from "../components/Layout/Wrapper/wrapper"
import "../components/scss/text.scss"
import { graphql } from "gatsby"
import FormCard from "../components/Form/FormCard"
import OurBenefits from "../components/Form/OurBenefits"
import Steps from "../components/Form/Steps"
import {EncodedCookieDataStore} from "../services/storage/datastore/EncodedCookieDataStore";
import {Base64DecoderService} from "../services/storage/decoder/Base64DecoderService";
import {Base64EncoderService} from "../services/storage/encoder/Base64EncoderService";
import {checkUtmParams} from "../services/sem/checkUtmParams";

class HomeTemplate extends Component {

  constructor(props) {
    super(props)

    const cookieUtm = new EncodedCookieDataStore({
      cookieName: 'utm_param',
      decoderService: new Base64DecoderService(),
      encoderService: new Base64EncoderService(),
    })

    this.state = {
      cookie: cookieUtm
    }
  }

  render() {
    checkUtmParams();

    const homeData = this.props.data.contentfulHome
    const formData = this.props.data.allContentfulFormType
    const stepsData = this.props.data.allContentfulSteps
    return (
      <Layout>
        <Wrapper>
          <JumbotronSimple title={`${homeData.jumbotronTitle}`}
          />
        </Wrapper>

        <Wrapper isFlex>
          {formData.nodes.map((node) => {
            if (node.show) {
              return (
                <FormCard key={node.id} data={node}/>
              )
            }
          })
          }
        </Wrapper>
        <OurBenefits data={homeData}/>
        <Steps data={stepsData}/>
      </Layout>
    )
  }
}

export default HomeTemplate

export const pageQuery = graphql`
    query {
        allContentfulAsset{
            edges{
                node{
                  localFile {
                    publicURL
                  }
                }
            }
        }
        contentfulHome {
            jumbotronTitle
            benefit1
            benefit2
            benefit3
            benefit3
            benefit4
        }
        allContentfulFormType (
            filter: {
                show:{eq: true}
            }
        ){
            nodes{
                id
                name
                metaDescription
                formTitle
                slug
                show
                governmentPdf{
                    file{
                        url
                    }
                }
                formImage {
                    id
                    fluid{
                        sizes
                        src
                        srcSet
                    }
                }
                name
                type
                requeriments{
                    json
                }
            }
        }
        allContentfulSteps (
            sort: {fields: [createdAt], order: ASC}
        ){
            edges{node {
                id
                step
            }
            }
        }
    }
`
